import React from 'react'

import {
  Flex,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Icon as ChromeIcon } from 'src/chrome'
import { Portal } from 'src/portal/portal'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { useActions, useSelector, useState } from 'src/hooks'
import { PlanFragment } from 'src/legacy_graphql'
import { IconType } from 'src/design_system/atoms/icons/types'
import useHandleProductActions from 'src/pricing_page/hooks/useHandleProductActions'
import { AnimatePresence } from 'framer-motion'
import { ToasterNotification } from 'src/editor/components/MobileEditorToolbar/components/ToasterNotification'
import { StepPackage } from './StepPackage'
import { StepSubscription } from './StepSubscription'
import { SubscriptionType } from 'src/user/fragments'
import { offeredAffiliateStripeSkus } from 'src/helpers/supportedUpsaleProducts'

type PackageBundlerProps = {
  packagePlan: PlanFragment
  packageVariantId: string
  premiumSubVariantIds: string[]
  addonVariantIds?: string[]
  onClose: () => void
}

enum Steps {
  Package,
  Subscription,
  Confirmation,
}

const pillStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#FFF',
  borderRadius: '50px',
  padding: '0.25rem 1rem',
  zIndex: 3,
  border: 'solid #E8FAF0 4px',
}

export const PackageBundler: React.FC<PackageBundlerProps> = ({
  packagePlan,
  packageVariantId,
  premiumSubVariantIds,
  addonVariantIds,
  onClose,
}) => {
  const account = useSelector(state => state.user.account)
  const isMobile = useSelector(state => state.window.width <= 626)
  const actions = useActions()

  const [step, setStep] = useState<Steps>(Steps.Package)
  const [selectedPlan, setSelectedPlan] = useState<PlanFragment | undefined>()
  const [selectedAddons, setSelectedAddons] = useState<string[]>([])
  const {
    addToCart,
    hasCheckoutError,
    checkoutErrorMessage,
    isCreatingCheckout,
    isUpdatingCheckout,
  } = useHandleProductActions(packagePlan.stripeId)

  const isLoading = isCreatingCheckout || isUpdatingCheckout
  const affiliateSubscription =
    account &&
    account?.subscriptions.find((sub: SubscriptionType) =>
      offeredAffiliateStripeSkus.map(sku => sku.id).includes(sub.sku),
    )
  const shouldShowAddons = !account || !affiliateSubscription // TODO: Be smarter about this in the future, check if addons contain affiliate option

  const onSkipPackage = () => {
    if (step < Steps.Confirmation) addToCart(packageVariantId)
    onClose()
  }

  const onContinuePackage = (plan: PlanFragment, addons?: string[]) => {
    setSelectedAddons(addons ?? [])
    setSelectedPlan(plan)
    setStep(Steps.Subscription)
  }

  const onBackSubscription = () => {
    setStep(Steps.Package)
  }

  const onContinueSubscription = (selectedPlanVariantId: string) => {
    addToCart(
      [
        { variantId: packageVariantId, quantity: 1 },
        { variantId: selectedPlanVariantId, quantity: 1 },
      ].concat(
        selectedAddons.flatMap(selectedAddOn => {
          return { variantId: selectedAddOn, quantity: 1 }
        }),
      ),
    )
    actions.checkoutOpenState(true)
    onClose()
  }

  return (
    <Portal>
      <div
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: '#a5a8ab78',
          zIndex: 1000,
          backdropFilter: 'blur(4px)',
        }}
      />
      <div
        style={{
          position: 'fixed',
          top: isMobile ? undefined : '20%',
          left: isMobile ? '0' : '50%',
          bottom: isMobile ? 0 : undefined,
          transform: isMobile ? undefined : 'translate(-50%, -20%)',
          minWidth: isMobile
            ? '100%'
            : step === Steps.Package
            ? '50vw'
            : '500px',
          maxWidth: '90vw',
          maxHeight: '80vh',
          overflowY: 'auto',
          zIndex: 1001,
          borderRadius: '1rem',
          backgroundColor: '#F8F5F6',
          boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
        }}
      >
        {hasCheckoutError && checkoutErrorMessage && (
          <AnimatePresence>
            <ToasterNotification
              backgroundColor={{
                swatch: 'danger',
                shade: '_500',
              }}
              icon={{
                size: 'xSmall',
                name: 'information',
                primaryColor: 'inverseHeadingText',
                iconContainerColor: { swatch: 'success', shade: '_400' },
              }}
              label={{
                color: 'inverseHeading',
                type: 'footnote',
                content: checkoutErrorMessage,
              }}
            />
          </AnimatePresence>
        )}
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Flex
            justifyContent="space-between"
            boxShadow="light"
            style={{
              backgroundColor: '#FFF',
              borderRadius: '1rem 1rem 0 0',
              padding: '0.75rem 2rem',
              position: isMobile ? 'fixed' : 'sticky',
              top: isMobile ? undefined : 0,
              left: isMobile ? 0 : undefined,
              right: isMobile ? 0 : undefined,
              zIndex: 4,
            }}
          >
            <Flex alignItems="center">
              {step === Steps.Package ? (
                <Icon
                  name={packagePlan.icon.toLowerCase() as IconType}
                  color="#F34293"
                  size={20}
                />
              ) : (
                <ChromeIcon
                  icon="SOCLOGO"
                  color="black"
                  size={20}
                  style={{ display: 'flex' }}
                />
              )}
              <Text
                type="largeBody"
                weight="bold"
                color="primaryHeading"
                outset={{ left: 'x1' }}
              >
                {step === Steps.Package
                  ? packagePlan.title
                  : step === Steps.Subscription
                  ? 'Details of Your Package'
                  : 'Confirmed Purchase!'}
              </Text>
            </Flex>
            <Flex
              borderRadius="small"
              width="34px"
              height="34px"
              style={{ backgroundColor: '#F8F5F6' }}
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
              onClick={onSkipPackage}
            >
              <Icon name="x" size={12} />
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            inset={{ horizontal: 'x4', bottom: 'x2' }}
            style={{
              paddingTop: isMobile ? '80px' : '1rem',
              flex: '1',
              overflowY: 'auto',
            }}
          >
            <Text
              type="largeBody"
              weight="semiBold"
              alignment="center"
              content={
                step === Steps.Package
                  ? 'Great choice! This package also unlocks our Premium Monthly Memberships. Add one to your package now and get your first month free!'
                  : step === Steps.Subscription
                  ? 'Review your selected products below, then add them to your cart.'
                  : 'Way to go! Get started on your gratitude sharing efforts and make a difference today.'
              }
            />

            <Flex
              justifyContent="center"
              alignItems="center"
              columnGap="x1_5"
              position="relative"
              outset={{ top: 'x4' }}
            >
              <div
                style={{
                  position: 'absolute',
                  height: '4px',
                  left: '10px',
                  right: '10px',
                  backgroundColor: '#FFF',
                  zIndex: 1,
                }}
              />
              <div
                style={{
                  position: 'absolute',
                  height: '4px',
                  left: '10px',
                  right:
                    step === Steps.Subscription
                      ? '50%'
                      : step === Steps.Confirmation
                      ? '20%'
                      : undefined,
                  backgroundColor: '#E8FAF0',
                  zIndex: 2,
                }}
              />
              <Flex style={pillStyle}>
                <Icon name="v_check" color="#12A150" size={15} />
                <Spacer space="x1" orientation="horizontal" />
                <Text
                  type="caption"
                  weight="semiBold"
                  style={{ fontSize: '13px', color: '#12A150' }}
                >
                  Package
                </Text>
              </Flex>
              <Flex
                style={{
                  ...pillStyle,
                  margin: step > 0 ? '0' : '0 4px',
                  border: step > 0 ? 'solid #E8FAF0 4px' : undefined,
                }}
              >
                {step > 0 ? (
                  <Icon name="v_check" color="#12A150" size={15} />
                ) : (
                  <Text
                    type="caption"
                    weight="extraBold"
                    content="2"
                    color="primaryHeading"
                  />
                )}
                <Spacer space="x1" orientation="horizontal" />
                <Text
                  type="caption"
                  weight="semiBold"
                  style={{
                    fontSize: '13px',
                    color: step > 0 ? '#12A150' : '#404040',
                  }}
                >
                  Subscription
                </Text>
              </Flex>
              <Flex
                style={{
                  ...pillStyle,
                  margin: step > 1 ? '0' : '0 4px',
                  border: step > 1 ? 'solid #E8FAF0 4px' : undefined,
                }}
              >
                {step > 1 ? (
                  <Icon name="v_check" color="#12A150" size={15} />
                ) : (
                  <Text
                    type="caption"
                    weight="extraBold"
                    content="3"
                    color="primaryHeading"
                  />
                )}
                <Spacer space="x1" orientation="horizontal" />
                <Text
                  type="caption"
                  weight="semiBold"
                  style={{
                    fontSize: '13px',
                    color: step > 1 ? '#12A150' : '#404040',
                  }}
                >
                  Confirmation
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {isLoading && (
            <Flex
              width="100%"
              alignItems="center"
              justifyContent="center"
              inset="x6"
            >
              <LoadingSpinner size="xLarge" />
            </Flex>
          )}
          {!isLoading && step === Steps.Package && (
            <StepPackage
              onSkip={onSkipPackage}
              onContinue={onContinuePackage}
              premiumSubVariantIds={premiumSubVariantIds}
              addonVariantIds={addonVariantIds}
              isMobile={isMobile}
              shouldShowAddons={shouldShowAddons}
            />
          )}
          {!isLoading && step === Steps.Subscription && selectedPlan && (
            <StepSubscription
              onBack={onBackSubscription}
              onContinue={onContinueSubscription}
              packagePlan={packagePlan}
              selectedAddons={selectedAddons}
              selectedPlan={selectedPlan}
              isMobile={isMobile}
            />
          )}
        </div>
      </div>
    </Portal>
  )
}
