import { Section } from 'src/card_history/types'
import { openRoute as openBaseRoute } from 'src/redux/actions/routes'
import { CatalogRoute, CatalogSubroute } from 'src/catalog/routes/CatalogRoute'
import {
  GiftStoreRoute,
  GiftStoreSubroute,
} from 'src/gift_store/routes/GiftStoreRoute'
import { OrdersRoute, OrdersSubroute } from 'src/orders/routes/OrdersRoute'
import { CampaignStoreRoute } from 'src/campaign_store/routes/CampaignStoreRoute'
import { RelationshipManagerRoute } from 'src/contacts/routes/RelationshipManagerRoute'
import { GroupsRoute } from 'src/contacts/routes/GroupsRoute'
import { CardRoute } from 'src/card/routes/CardRoute'
import {
  CampaignRoute,
  CampaignSubroute,
} from 'src/campaigns/routes/CampaignRoute'
import {
  CampaignsRoute,
  CampaignsSubroute,
} from 'src/campaigns/routes/CampaignsRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { AccountRoute } from 'src/dashboard/routes/AccountRoute'
import { NotificationsRoute } from 'src/dashboard/routes/NotificationsRoute'
import { DraftedOrdersRoute } from 'src/dashboard/routes/DraftedOrdersRoute'
import { DraftedCardsRoute } from 'src/dashboard/routes/DraftedCardsRoute'
import {
  CardHistoryRoute,
  CardHistorySubroute,
} from 'src/dashboard/routes/CardHistoryRoute'
import { ImportContactsRoute } from 'src/contacts/routes/ImportContactsRoute'
import { PaymentSettingsRoute } from 'src/dashboard/routes/PaymentSettingsRoute'
import { SearchFilter } from 'src/chrome/FilteredSearchBar/types'

import { BaseRoute, BaseSubroute } from '../../routes/BaseRoute'
import { CreateCampaignRoute } from 'src/campaigns/routes/CreateCampaignRoute'
import { EditContactRoute } from 'src/contacts/routes/EditContactRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import { CardCategoryRoute } from 'src/catalog/routes/CardCategoryRoute'
import { CardCollectionRoute } from 'src/catalog/routes/CardCollectionRoute'
import { LeaderBoardRoute } from 'src/dashboard/routes/LeaderBoardRoute'
import { ShareSettingsRoute } from 'src/dashboard/routes/ShareSettingsRoute'
import { DashboardSettingsRoute } from 'src/dashboard/routes/Settings'

const openRoute = (
  subroute: BaseSubroute,
  args: {
    [key: string]: string | undefined
  },
) => openBaseRoute(BaseRoute(subroute), args)

export const openCatalog = (subroute?: CatalogSubroute) =>
  openRoute(CatalogRoute(subroute), {})

export const openCardCategory = (categoryId: string) =>
  openRoute(CatalogRoute(CardCategoryRoute(categoryId)), {})

export const openCardCollection = (collectionId: string) =>
  openRoute(CatalogRoute(CardCollectionRoute(collectionId)), {})

export const openCard = (cardId: string) => openRoute(CardRoute(cardId), {})

export const openOrder = (orderId: string, subroute?: OrdersSubroute) =>
  openRoute(OrdersRoute(orderId, subroute), {})

export const openGiftStore = (
  giftStoreSubroute?: GiftStoreSubroute,
  highLevelCategory?: string,
) => openRoute(GiftStoreRoute(giftStoreSubroute, highLevelCategory), {})

export const openCampaignStore = (categoryId?: string) =>
  openRoute(CampaignsRoute(CampaignStoreRoute(categoryId)), {})

export const openRelationshipManager = (subroute?: GroupsRoute) =>
  openRoute(RelationshipManagerRoute(subroute), {})

export const openGroups = () => openRoute(GroupsRoute(), {})

export const openContactsImporter = () => openRoute(ImportContactsRoute(), {})

export const openCampaigns = (subroute?: CampaignsSubroute) =>
  openRoute(CampaignsRoute(subroute), {})

export const openCreateCampaign = () =>
  openRoute(CampaignsRoute(CreateCampaignRoute()), {})

export const openCampaign = (campaignId: string, subroute?: CampaignSubroute) =>
  openRoute(CampaignsRoute(CampaignRoute(campaignId, subroute)), {})

export const openPricing = (
  args: {
    [key: string]: string | undefined
  } = {},
) => openRoute(DashboardRoute(PricingRoute()), args)

export const openUnauthPricing = () => openRoute(PricingRoute(), {})

export const openAccount = (
  args: {
    [key: string]: string | undefined
  } = {},
) => openRoute(DashboardRoute(AccountRoute()), args)

export const openLeaderBoards = () =>
  openRoute(DashboardRoute(LeaderBoardRoute()), {})

export const openShareSettings = () =>
  openRoute(DashboardRoute(ShareSettingsRoute()), {})

export const openDashboardSettingsRoute = () =>
  openRoute(DashboardRoute(DashboardSettingsRoute()), {})

export const openNotifications = () =>
  openRoute(DashboardRoute(NotificationsRoute()), {})

export const openDraftedOrders = () =>
  openRoute(DashboardRoute(DraftedOrdersRoute()), {})

export const openDraftedCards = () =>
  openRoute(DashboardRoute(DraftedCardsRoute()), {})

export const openCardHistory = (
  section?: Section,
  filters?: SearchFilter[],
  subroute?: CardHistorySubroute,
) => openRoute(DashboardRoute(CardHistoryRoute(section, filters, subroute)), {})

export const openPaymentSettings = () =>
  openRoute(DashboardRoute(PaymentSettingsRoute()), {})

export const openContact = (contactId: string) =>
  openRoute(RelationshipManagerRoute(EditContactRoute(contactId)), {})

export const openPromo = () => openRoute(PromoRoute(), {})
