import { Operation } from '../../../__generated__/globalTypes'

import getGratitudeDayMarketingContentString from '../GetGratitudeDayMarketingContent.graphql'

export type GetGratitudeDayMarketingContentData = {
  worldGratitudeImageUrl: {
    __typename: 'MarketingURL'
    url: string
  }
}

export type GetGratitudeDayMarketingContent = Operation<
  GetGratitudeDayMarketingContentData
>

export const GetGratitudeDayMarketingContent = (): GetGratitudeDayMarketingContent => ({
  query: getGratitudeDayMarketingContentString,
  variables: undefined,
})
