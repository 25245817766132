import React from 'react'
// @src imports
import { InvalidMagicLinkRoute } from 'src/routes/InvalidMagicLinkRoute'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { RelationshipManagerRoute } from 'src/contacts/routes/RelationshipManagerRoute'
import { GroupsRoute } from 'src/contacts/routes/GroupsRoute'
import { ImportContactsRoute } from 'src/contacts/routes/ImportContactsRoute'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { CampaignsRoute } from 'src/campaigns/routes/CampaignsRoute'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { useSelector } from 'src/hooks'
import { CardRoute } from 'src/card/routes/CardRoute'
import { FourOFourRoute } from 'src/404/routes/404Route'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import { CertifiedCoachingRoute } from 'src/coaching/routes/CoachingCertificationRoute'
import { PointsConversionRoute } from 'src/points_conversion/routes/PointsConversionRoute'
import { ConfirmPaymentRoute } from 'src/saleor/routes/ConfirmPaymentRoute'
import { ConfirmOrderRoute } from 'src/saleor/routes/ConfirmOrderRoute'

const Orders = React.lazy(() => import('src/orders/containers/Orders'))
const CampaignsRouter = React.lazy(
  () => import('src/campaigns/components/CampaignsRouter/CampaignsRouter'),
)
const Dashboard = React.lazy(
  () => import('src/dashboard/containers/Dashboard/Dashboard'),
)
const CatalogPage = React.lazy(
  () => import('src/catalog/containers/CatalogPage'),
)
const GroupManager = React.lazy(
  () => import('src/contacts/containers/GroupManager'),
)
const ImportManager = React.lazy(() => import('src/import-contacts'))
const GiftStore = React.lazy(
  () => import('src/gift_store/containers/GiftStore'),
)
const InvalidMagicLink = React.lazy(
  () => import('src/chrome/InvalidMagicLink/InvalidMagicLink'),
)
const Card = React.lazy(() => import('src/card'))
const Promo = React.lazy(() => import('src/promo/components/Promo'))
const FourOFour = React.lazy(() => import('src/404/404'))

const CertifiedCoaching = React.lazy(async () => {
  const module = await import(
    'src/coaching/components/CertifiedCoaching/CertifiedCoaching'
  )
  return { default: module.CertifiedCoaching }
})

const PointsConversionPage = React.lazy(async () => {
  const module = await import(
    'src/points_conversion/pages/PointsConversionPage'
  )
  return { default: module.PointsConversionPage }
})

const ContactManagerSelector = React.lazy(
  () => import('src/contacts/containers/ContactManagerSelector'),
)

const PricingPage = React.lazy(async () => {
  const module = await import('src/pricing_page/PricingPage')
  return { default: module.PricingPage }
})

const ConfirmPaymentPage = React.lazy(async () => {
  const module = await import('src/saleor/pages/ConfirmPaymentPage')
  return { default: module.ConfirmPaymentPage }
})

const ConfirmOrderPage = React.lazy(async () => {
  const module = await import('src/saleor/pages/ConfirmOrderPage')
  return { default: module.ConfirmOrderPage }
})

const Router: React.FC = () => {
  const route = useSelector(state => state.route)

  switch (route.subroute.path) {
    case InvalidMagicLinkRoute.path:
      return <InvalidMagicLink route={route.subroute} />
    case PricingRoute.path:
      return <PricingPage route={route.subroute} />
    case CatalogRoute.path:
      return <CatalogPage route={route.subroute} />
    case GroupsRoute.path:
      return <GroupManager />
    case RelationshipManagerRoute.path:
      return <ContactManagerSelector route={route.subroute} />
    case ImportContactsRoute.path:
      return <ImportManager />
    case OrdersRoute.path:
      return <Orders route={route.subroute} />
    case CampaignsRoute.path:
      return <CampaignsRouter route={route.subroute} />
    case GiftStoreRoute.path:
      return <GiftStore route={route.subroute} />
    case DashboardRoute.path:
      return <Dashboard route={route.subroute} />
    case CardRoute.path:
      return <Card cardId={route.subroute.cardId} />
    case PromoRoute.path:
      return <Promo />
    case FourOFourRoute.path:
      return <FourOFour />
    case CertifiedCoachingRoute.path:
      return <CertifiedCoaching />
    case PointsConversionRoute.path:
      return <PointsConversionPage />
    case ConfirmPaymentRoute.path:
      return <ConfirmPaymentPage />
    case ConfirmOrderRoute.path:
      return <ConfirmOrderPage route={route.subroute} />
    default:
      return <FourOFour /> // Fallback route, in case the path doesn't match
  }
}

export default Router
